//helpers
.no-padding {
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}

h1, h2, h3 {
  font-family: 'Bebas Neue', cursive !important;
  font-weight: 500 !important;
}

h3 {
  font-size: 1.5em;
}

p {
  color: #828282;
}

//Topo da página
header {
  position: relative;
  background-color: transparent;
  z-index: 99;
  padding-top: 1.5em;

  @media(max-width: $screen-sm-max) {
    border-bottom: none;
  }

  img {
    max-width: 100%;
  }

  i {
    font-size: 1.2em;
  }

  a {
    font-family: 'Bebas Neue', cursive !important;
    font-size: 1.125em;
    opacity: 1;
    color: #1F2D3D;

    &:hover {
      text-decoration: underline !important;
      color: #1F2D3D;
    }

  }

  .navbar {

    .row {
      @media(max-width: $screen-xs-max) {
        margin: 0 auto;

        > .col-12 {
          padding: 0;
        }
      }
    }

    .navbar-collapse.show,
    .navbar-collapse.collapsing {
      position: absolute;
      width: 94%;
      margin-left: -12px;
      background-color: #F4CCCB;
    }

  }

  .navbar-nav {
    padding-top: 1.3em;

    @media(max-width: $screen-xs-max) {
      text-align: center;
    }
  }

  .navbar-brand {
    img {
      max-height: 91px;
      width: auto;
    }
  }

  //Configurando botão do menu no mobile
  .navbar-toggler {
    background: #F4CCCB;

    &:focus,
    &:active {
      background: darken(#f4cccb, 5%);
      outline: none;
    }

    .navbar-toggler-icon {
      background: #000;
      display: block;
      height: 2px;
      margin-bottom: 3px;

      &:first-of-type {
        margin-top: 10px;
      }

      &:last-of-type {
        margin-bottom: 10px;
      }
    }
  }

}

//Vídeo

.s-topo-pagina {
  position: absolute !important;
  top: 0;
  display: contents;
  width: 100%;

  .vidbg-container {
    height: 630px !important;

    @media(max-width: $screen-sm-max) {
      height: 750px !important;
    }

    @media(max-width: $screen-xs-max) {
      height: 650px !important;
    }

  }

  .s-topo-pagina-conteudo {
    position: relative;
    width: 100%;
    padding: 3em 15% 8em 15%;

    @media(max-width: $screen-xs-max) {
      padding-left: 0;
      padding-right: 0;
    }

    h1, p {
      color: #1F2D3D;
    }

    h1 {
      font-size: 5em;

      @media (min-width: 300px) and (max-width: 767px) {
        font-size: 4em;
      }

    }

    .btn {
      padding-top: .8em;
      padding-bottom: .8em;
      font-size: 1em;
    }

    &:before {
      content: " ";
      position: absolute;
      left: 50%;
      bottom: 3em;
      width: 20px;
      height: 27px;
      margin-left: -10px;
      background-image: url(../img/icon-mouse-topo.png);
      background-repeat: no-repeat;
      background-position: center center;

      @media(max-width: $screen-xs-max) {
        bottom: 5em;
      }

    }
  }
}

//Padrão de seção do site
.s-padrao {
  padding-top: 6em;
  padding-bottom: 6em;

  .b-titulo-subtitulo {
    padding: 0 25%;

    @media(max-width: $screen-xs-max) {
      padding: 0 15px;
    }

    @media(max-width: $screen-sm-max) {
      padding: 0 10%;
    }

  }

}

//Sobre
#sobre {
  .b-imagem-sobre {
    max-height: 580px;
    width: auto;
    text-align: right;

    background-image: url("../img/bg-pontos.png");
    background-repeat: no-repeat;
    background-position: left top;

    //media query tablet
    @media (min-width: 768px) and (max-width: 991px) {
      background-position: -43%;
    }

  }
}

//Seção Serviço
.s-servico {

  .container {
    background-image: url("../img/bg-pontos.png");
    background-repeat: no-repeat;
    background-position: left top;
  }

  .s-servico-item {

    .s-servico-item-titulo {
      font-weight: 500;
    }

    h3 {
      font-family: 'Playfair Display', serif !important;
      color: #CE8F8E;
      line-height: normal;
    }

    .b-img {
      img {
        max-width: 100% !important;
      }
    }
  }

  .s-servico-item-solucao-midia-social {
    background-image: url("../img/bg-pontos.png");
    background-repeat: no-repeat;
    background-position: right top;
  }
}

//Seção de Portfolio
.s-portfolio {
  .container {
    background-image: url("../img/bg-pontos.png");
    background-repeat: no-repeat;
    background-position: left top;
  }

  .listagem-portfolio {
    .listagem-portfolio-item {
      position: relative;
      height: 290px;
      background-size: cover;
      background-repeat: no-repeat;

      @media(max-width: $screen-sm-max) {
        -webkit-background-size: contain;
        background-size: contain;
        height: 250px;
      }

      @media(max-width: $screen-xs-max) {
        height: auto;
      }

      a {

        img {
          -webkit-transition: 0.6s ease;
          transition: 0.6s ease;

          border-radius: 10px;
        }

        h3 {
          -webkit-transition: 0.6s ease;
          transition: 0.6s ease;
        }

        &:hover {
          img {
            position: relative;
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
            z-index: 999;
          }

          h3 {
            transition: 0.6s;
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
            z-index: 9999;
            visibility: visible;
          }
        }
      }

      h3 {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding-bottom: 0;
        padding-top: 3px;
        font-weight: 500;
        color: darken(#F4CCCB, 5%);
        text-shadow: 4px 2px 19px rgba(51, 51, 51, 0.73);
        background: rgba(51, 51, 51, 0.73);
        visibility: hidden;
        -webkit-transition: 0.6s ease;
        transition: 0.6s ease;

      }
    }
  }
}

//CTA
.s-cta {
  background-color: #F4CCCB;

  p {
    margin-bottom: 0;
  }

  .btn {
    background-color: #414141;
    color: #fff;
    font-family: "Playfair Display", serif !important;
    border-radius: 0;

    &:hover {
      background-color: lighten(#414141, 10%);
    }
  }
}

//Depoimento de cliente

.s-depoimento {

  .container {
    background-image: url("../img/bg-pontos.png");
    background-repeat: no-repeat;
    background-position: left top;
  }

  .titulo-secao {
    padding-top: 2.5em;

    @media(max-width: $screen-xs-max) {
      padding-top: 0;
    }
  }

  .c-depoimento {

    margin-left: 20%;
    margin-right: 20%;

    @media(max-width: $screen-xs-max) {
      margin-left: 0;
      margin-right: 0;
      padding-top: 2em;
    }

    .carousel-inner {
      overflow-x: hidden !important;
      background-color: #F7F7F7;
      border-radius: 15px;
    }

    .c-depoimento-item {
      min-height: 300px;
      height: auto;
      padding: 2em;

      @media(max-width: $screen-xs-max) {
        padding-left: 20px;
        padding-right: 20px;
      }

      &.active {
        min-height: 300px;
        height: auto;

        p {
          line-height: 1.5em;
        }
      }

      .c-depoimento-conteudo {
        text-align: center;
        padding-bottom: 1.5em;

        p {
          line-height: 1.5em;
          text-align: center;
          color: #3C4858;
        }
      }

      .c-depoimento-foto {
        padding-bottom: 2em;
        text-align: center;

        img {
          height: 100px;
          width: 100px;
          margin: 0 auto;
        }
      }

      .c-depoimento-info {
        text-align: center;
        h2 {
          font-weight: bold !important;
          padding-bottom: 0;
          font-family: 'Playfair Display', serif !important;
          font-size: 1.3em;
        }

        .c-depoimento-info-profissao {
          color: #000;
          padding-bottom: 1em;
        }

        p {
          margin: 0 auto;
        }

        .c-depoimento-categoria-txt {
          display: inline-block;
          padding-right: .5em;
          font-size: .9em;

          &:last-of-type {
            padding-right: 0;
          }
        }
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      width: 40px;
      opacity: 1;
      filter: alpha(opacity=100);
      background-image: none;
      background-repeat: no-repeat;
      text-shadow: none;

      @media(max-width: $screen-xs-max) {
        width: 20px;
      }

      i {
        position: relative;
        z-index: 99999;
        font-size: 24px;
        color: darken(#F4CCCB, 5%);
      }
    }

    .carousel-control-prev {
      i {
        margin-left: -5em;

        @media(max-width: $screen-xs-max) {
          margin-left: -1.5em;
        }

      }
    }

    .carousel-control-next {
      i {
        margin-right: -5em;

        @media(max-width: $screen-xs-max) {
          margin-right: -1.5em;
        }

      }
    }

  }
}

//Equipe
.s-equipe {

  .container {
    background-image: url("../img/bg-pontos.png");
    background-repeat: no-repeat;
    background-position: left top;
  }

  .s-equipe-item {

    .s-equipe-item-img {

    }

    .s-equipe-item-conteudo {

      h3 {

      }

      p {
        margin-bottom: .5em;
      }
    }

  }
}

//Seção de Clientes
.s-cliente {

  .container {
    position: relative;

    &:after {
      content: " ";
      position: absolute;
      right: -6em;
      top: -10em;
      width: 200px;
      height: 350px;
      margin-left: -10px;
      background-image: url(../img/bg-pontos.png);
      background-repeat: no-repeat;
      background-position: center center;

      @media(max-width: $screen-sm-max) {
        right: 0;
      }

      @media(max-width: $screen-xs-max) {
        right: 0;
      }

    }

  }

  .s-padrao-titulo {
    position: relative;
    margin-top: 2em;
    font-weight: 500;
    font-size: 1.625em;
    padding-right: 5em;

    @media(max-width: $screen-sm-max) {
      font-size: 1.2em;
      padding-right: 2em;
    }

    @media(max-width: $screen-xs-max) {
      margin-top: 0;
      padding-right: 40%;
      font-size: 1.625em;
    }
  }

  i {
    margin-left: .5em;
    font-size: .7em;
    position: absolute;
    right: 4em;
    top: 1.2em;

    @media(max-width: $screen-sm-max) {
      font-size: .5em;
    }

    @media(max-width: $screen-xs-max) {
      position: relative;
      right: 0;
      top: 0;
      transform: rotate(90deg);
    }
  }

  .slick-slide {
    img {
      max-height: 180px;

      @media(max-width: $screen-sm-max) {
        max-height: 150px;
      }

      @media(max-width: $screen-xs-max) {
        max-height: 100%;
      }
    }
  }

  .slick-arrow {
    background: #F4CCCB;
    width: 30px;
    height: 30px;
    border-radius: 50px;

    &:hover,
    &:active,
    &:focus {
      background: darken(#F4CCCB, 5%);
    }

    &:before {
      font-size: 14px;
      line-height: 2.2;
      font-weight: bold;
    }

    &.slick-prev {
      left: -49px;

      @media(max-width: $screen-xs-max) {
        left: 0;
        z-index: 99;
      }
    }

    &.slick-next {
      @media(max-width: $screen-xs-max) {
        right: 0;
      }
    }

  }

}

.s-contato {

  .container {
    background-image: url("../img/bg-pontos.png");
    background-repeat: no-repeat;
    background-position: left bottom;
  }

  .titulo-secao {
    padding-top: 1.4em;

    @media(max-width: $screen-xs-max) {
      padding-top: 0;
    }
  }

  .col-conteudo {
    padding-right: 10%;

    @media(max-width: $screen-xs-max) {
      padding-right: 0;
    }

    i {
      font-size: 1.2em;
      color: #414141;

      &:hover {
        color: darken(#F4CCCB, 5%);
      }
    }

    a {
      color: #828282;
    }

  }

  .b-formulario {
    background-color: #fff;
    padding-left: 2em;
    padding-right: 2em;
    border-radius: 10px;

    @media(max-width: $screen-xs-max) {
      margin-top: 2em;
      padding-left: 15px;
      padding-right: 15px;
    }

    p {
      color: #3C4858;
    }

    //Estilizando o formulário
    .form-group {
      .form-control {
        background-color: transparent;
        border: 1px solid #CDCDCD;
        font-size: 1em;
        border-radius: 0;
        height: 56px;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: #828282;
          font-size: 16px;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: #828282;
          font-size: 16px;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: #828282;
          font-size: 16px;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: #828282;
          font-size: 16px;
        }

        &:active,
        &:focus {
          border: 1px solid #000;
        }
      }
    }

    textarea {
      height: 150px !important;
    }

    button {
      border-radius: 0;
      background-color: #414141;
      padding-top: .6em !important;
      padding-bottom: .6em !important;
      padding-left: 2.5em !important;
      padding-right: 2.5em !important;
      font-family: 'Playfair Display', serif !important;
      color: #fff !important;

      &:hover {
        background-color: darken(#F4CCCB, 5%);
        color: #414141 !important;
      }

    }
  }
}

//Footer
.s-footer {
  background-color: #414141;
  margin-top: -10px;
  padding-top: 4em;
  padding-bottom: 0;
  a {
    font-family: 'Bebas Neue', cursive !important;
    color: #fff;

  }

  i {
    font-size: 1.2em;
  }

  .menu-footer {

    a {
      &:hover {
        text-decoration: underline;
      }

    }

    li {
      &:first-of-type {
        a {
          padding-left: 0;
        }
      }

      @media (min-width: 300px) and (max-width: 767px) {
        display: block;

        a {
          padding-left: 0;
        }
      }
    }
  }

  img {
    max-height: 100px;
    width: auto;
  }

  .social-footer {
    padding-top: .7em;

    i {
      &:hover {
        color: darken(#F4CCCB, 5%);
      }
    }

  }

  .copyright {
    background: #fff;

    .container {
      padding: 1em 15px;
      border-top: 1px solid #E6EAF2;
    }

    .descricao-copyright {
      margin-top: 5px;
      text-align: left;

      p {
        margin-bottom: 0;
        margin-top: -3px;
        padding: 0;
        font-size: 14px;
      }

      @media(max-width: $screen-xs-max) {
        text-align: center;
      }
    }

    .logo-nano-incub {
      @media(max-width: $screen-xs-max) {
        text-align: center;
        padding-top: 10px;
      }

      img {
        height: 15px;
        width: auto;
        margin-top: 5px;
        float: right;
        @media(max-width: $screen-xs-max) {
          float: none;
        }
      }
    }
  }
}

.btn-enviado {
  background-color: #414141;
  color: #fff;
  font-family: "Playfair Display", serif !important;
  border-radius: 0;

  &:hover {
    background-color: lighten(#414141, 10%);
    color: #fff;
  }
}
